<template>
  <div style="background-color: #F9F7ED">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  created() {},
};
</script>

<style scoped></style>
